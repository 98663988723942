/* eslint-disable react/no-array-index-key */
import React from 'react'
import Layout from '~components/Layout'
import { Container } from 'react-bootstrap'
import S from '../../components/seo'
import * as s from './Policy.module.scss'

const Policy = ({
  seo,
  title,
  blocks,
  header,
  headerMobileExtras,
  footer,
  socials,
  cellar,
  contactInfo,
  cookiesOffcanvas,
}) => (
  <Layout
    {...{
      header,
      headerMobileExtras,
      footer,
      socials,
      cellar,
      contactInfo,
      cookiesOffcanvas,
    }}
  >
    <S {...seo} />

    <Container as="section" className={s.policy}>
      <h1 className={s.policy_heading}>{title}</h1>
      <div className={s.policy_content}>
        {blocks?.map(({ saveContent }, i) => (
          <div
            key={`b${i}`}
            dangerouslySetInnerHTML={{ __html: saveContent }}
          />
        ))}
      </div>
    </Container>
  </Layout>
)

export default Policy
