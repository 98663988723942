import React from 'react'
import { graphql } from 'gatsby'
import Policy from '~containers/Policy'

const PolicyPage = ({ data }) => (
  <Policy
    {...data.wpPage}
    header={data.header}
    headerMobileExtras={data.headerMobileExtras}
    footer={data.footer}
    socials={data.socials}
    cellar={data.cellar}
    contactInfo={data.contactInfo}
    cookiesOffcanvas={data.cookiesOffcanvas}
  />
)

export const query = graphql`
  query Policy(
    $headerSlug: String
    $headerMobileExtrasSlug: String
    $footerSlug: String
    $socialSlug: String
    $cellarSlug: String
    $cookiesOffcanvasSlug: String
    $privacyPolicySlug: String
  ) {
    header: wpMenu(slug: { eq: $headerSlug }) {
      headerExtras {
        headerButtonPrimary {
          text
          url
        }
      }
      menuItems {
        nodes {
          parentId
          label
          url
          navigationMenu {
            navRel
            navTarget
            navIsNotLocalizable
          }
          navigationHeader {
            headerNavIsDesktopOnly
            headerNavSubMenuTitle
            headerNavDescription
          }
          childItems {
            nodes {
              label
              url
              navigationMenu {
                navTarget
                navRel
                navIsNotLocalizable
              }
              navigationHeader {
                headerNavIconName
                headerNavDescription
              }
            }
          }
        }
      }
    }

    headerMobileExtras: wpMenu(slug: { eq: $headerMobileExtrasSlug }) {
      menuItems {
        nodes {
          label
          url
          navigationMenu {
            navRel
            navTarget
            navIsNotLocalizable
          }
        }
      }
    }

    footer: wpMenu(slug: { eq: $footerSlug }) {
      menuItems {
        nodes {
          label
          url
          navigationMenu {
            navRel
            navTarget
            navIsNotLocalizable
          }
        }
      }
    }
    socials: wpMenu(slug: { eq: $socialSlug }) {
      menuItems {
        nodes {
          label
          url
          navigationMenu {
            navRel
            navTarget
          }
          navigationSocial {
            navIconName
          }
        }
      }
    }
    cellar: wpMenu(slug: { eq: $cellarSlug }) {
      menuItems {
        nodes {
          label
          url
        }
      }
      cellarExtras {
        languageLink {
          text
          url
          target
          rel
          isNotLocalizable
        }
      }
    }
    contactInfo: wpComponent(slug: { eq: "contact-info" }) {
      contactInfo {
        contactInfo {
          address
          email
          phone
        }
      }
    }

    cookiesOffcanvas: wpComponent(slug: { eq: $cookiesOffcanvasSlug }) {
      cookiesOffcanvas {
        cookiesOffcanvas: cookiesoffcanvas {
          heading
          text
          buttonSecondary {
            text
            url
          }
          buttonPrimary {
            text
          }
        }
      }
    }

    wpPage(slug: { eq: $privacyPolicySlug }) {
      seo {
        title
        metaDesc
        opengraphImage {
          sourceUrl
        }
        opengraphTitle
        twitterTitle
        twitterDescription
        twitterImage {
          sourceUrl
        }
      }
      title
      blocks {
        saveContent
      }
    }
  }
`

export default PolicyPage
